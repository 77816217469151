import React, { useEffect, useState } from "react";
import { Card, CardImg, Container } from "react-bootstrap";
import "../../../theme/css/Event.css";
import Layout from 'react-masonry-list'
import { EventGalleryImg } from "./EventGalleryImg";
export const EventGallery = () => {
  const [items, setItem] = useState(EventGalleryImg);
  const [currentActive, setCuttentActive] = useState(0);
  const filterItem = (categItem, tabIndex) => {
    const updateItem = EventGalleryImg.filter((curElem) => {
      return curElem.category === categItem;
    });
    setItem(updateItem);
    setCuttentActive(tabIndex);
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  let widthHelper = 3;
  if (width <= 1000) {
    widthHelper = 2;
  }
  if (width < 767) {
    widthHelper = 1
  }

  return (
    <>
      <Container>
        <div className="EventGallery Title">
          <div className="Event-menu">
            <ul className="text-center Event-list list-unstyled">
              <li className={currentActive === 0 ? "active" : ""}>
                <span
                  onClick={() => {
                    setItem(EventGalleryImg);
                    setCuttentActive(0);
                  }}
                >
                  {" "}
                  All
                </span>
              </li>
              <li className={currentActive === 1 ? "active" : ""}>
                <span onClick={() => filterItem("Happening", 1)}>
                  {" "}
                  Happening{" "}
                </span>
              </li>
              <li className={currentActive === 2 ? "active" : ""}>
                <span onClick={() => filterItem("Upcoming", 2)}>
                  {" "}
                  Upcoming{" "}
                </span>
              </li>
              <li className={currentActive === 3 ? "active" : ""}>
                <span onClick={() => filterItem("Expired", 3)}> Expired </span>
              </li>
            </ul>
          </div>
          <div>
            <Layout
              colCount={widthHelper}
              items={items.map((photo) => (
                <li
                  className={`photo-item col-md-4 col-sm-6 col-xl-4 col-xs-12`}
                  key={photo.id}
                >
                  <Card>
                    <CardImg
                      src={photo.image}
                      alt="Event"
                      className="img-fluid mx-auto Event-Img-effect"
                    />
                    <Card.Body className="Event-body">
                      <Card.Title className="Event-text">
                        {photo.title}
                      </Card.Title>
                      <p>{photo.content}</p>
                    </Card.Body>
                  </Card>
                </li>
              ))
              }
              className="row"
            >
            </Layout>
          </div>
        </div>
      </Container>
    </>
  );
};
