import React from "react";

import { Register } from "./Register";
import "../../../theme/css/login.css";

export const RegisterMain = () => {
  return (
    <>
      <div className="LoginRegister">
        <Register />
      </div>
    </>
  );
};
