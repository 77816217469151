import img1 from "../../../assets/OurPCourse/course-img1.png";
import img2 from "../../../assets/OurPCourse/course-img2.png";
import img3 from "../../../assets/OurPCourse/course-img3.png";

export const EventGalleryImg = [
  {
    id: 1,
    image: img1,
    title: "Why Play Is Important in Preschool and Early",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Upcoming",
  },
  {
    id: 2,
    image: img2,
    title: "Surprising SUP Yoga Poses You’ll Want to Try This Summer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Happening",
  },
  {
    id: 3,
    image: img3,
    title: "7 Things I Learned From Doing One of Those Social Media Yoga",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Expired",
  },
  {
    id: 4,
    image: img1,
    title: "Why Play Is Important in Preschool and Early",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Happening",
  },
  {
    id: 5,
    image: img2,
    title: "Surprising SUP Yoga Poses You’ll Want to Try This Summer",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Upcoming",
  },
  {
    id: 6,
    image: img3,
    title: "7 Things I Learned From Doing One of Those Social Media Yoga",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Expired",
  },
  {
    id: 7,
    image: img3,
    title: "7 Things I Learned From Doing One of Those Social Media Yoga",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Upcoming",
  },
  {
    id: 9,
    image: img3,
    title: "7 Things I Learned From Doing One of Those Social Media Yoga",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Happening",
  },
  {
    id: 10,
    image: img3,
    title: "7 Things I Learned From Doing One of Those Social Media Yoga",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not.",
    category: "Happening",
  },
];
