import React from 'react'
import { Button } from "react-bootstrap";
import img1 from "../../../../assets/Banner/HeaderImg.png";
import { HomeBannerBox } from "./HomeBannerBox";


export const HomeBanner = () => {
 
  return (
    <>
      <div className="BannerImg Title">
        <div>
          <img src={img1} alt="BannerImg" className="img-fluid mx-auto" />
        </div>
        <div className="Banner-Content"  data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500" >
          <h5>Our OSA Consulting Tech Corp</h5>
          <h6 >Build Your Career in Full Stack Developer, QA Automation Engineer...</h6>
          <p >
          We have numerous industry experts ready to train you, providing 
          hands-on experience with live projects and in-demand tools to boost
           your earning potential.!
          </p>
          <div className="Banner-Content-Button" >
            <Button className="btn-box-common">Join for Free</Button>
          </div>
        </div>
        <HomeBannerBox/>
      </div>
    </>
  );
};
