import React, { useEffect } from "react";
import { Col, Card, Container, Row } from "react-bootstrap";
import img1 from "../../../../assets/Banner/brain.png";
import img2 from "../../../../assets/Banner/settings.png";
import img3 from "../../../../assets/Banner/team-management.png";
import Aos from "aos";
import "aos/dist/aos.css";

export const HomeBannerBox = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <Container>
        <div className="HomeBannerBox">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4}>
              <Card className="HomeBanner-Card" >
                <Card.Body>
                  <Card.Title>
                    <img src={img1} alt="HBannerBox"className="img-fluid mx-auto"  />
                  </Card.Title>
                  <Card.Subtitle>
                    <h4>Learn the latest skills Develop</h4>
                  </Card.Subtitle>
                  <Card.Text>
                    <h5>
                    Ready to take your skills to the next level? Explore our course catalog and start learning 
                    the latest skills that will propel your career forward. Whether you're aiming 
                    for professional growth, career advancement, or personal enrichment, 
                    LearnSkillsDevelop.com is your go-to destination for continuous learning and
                     development.
                    </h5>
                    <h6>Start Now !</h6>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4}  lg={4} md={4} sm={4} xs={12}>
              <Card className="HomeBanner-Card">
                <Card.Body>
                  <Card.Title className="HBtitle-img">
                    <img src={img2} alt="HBannerBox" className="img-fluid mx-auto HBtitle-img" />
                  </Card.Title>
                  <Card.Subtitle className="cardtitle ">
                    <h4>Find the Right Expert for Your Company</h4>
                  </Card.Subtitle>
                  <Card.Text className="service-text">
                    <h5>
                    Take the guesswork out of finding the expertise you need. Explore our
                     database of experts, review their profiles, and connect with the perfect
                      match for your company's goals and objectives. Whether you're seeking
                       strategic advisors, subject matter experts, or industry thought leaders,
                        ExpertFinder.com is your trusted partner in finding top talent.
                    </h5>
                    <h6>Start Now !</h6>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4}  lg={4} md={4} sm={4} xs={12}>
              <Card className="HomeBanner-Card">
                <Card.Body>
                  <Card.Title>
                    <img src={img3} alt="HBannerBox" className="img-fluid mx-auto" />
                  </Card.Title>
                  <Card.Subtitle className="cardtitle ">
                    <h4>Empower Team with Upskilling</h4>
                  </Card.Subtitle>
                  <Card.Text className="service-text">
                    <h5>
                      Investing in your employees' professional development not only enhances 
                      their individual capabilities but also drives organizational growth and
                       success. Join thousands of companies worldwide who trust OSA Consulting Tech Corp 
                       to empower your teams with the skills and knowledge needed to excel in today's 
                       competitive technogolies.
                    </h5>
                    <h6>Start Now !</h6>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
