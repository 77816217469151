import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap-v5";
import img1 from "../../../../assets/OnlineLearing/OLearing.png";
import AOS from "aos";
import "aos/dist/aos.css";

export const Learing = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  });

  return (
    <>
      <div className="Title">
        <Container>
          <div className="Title">
            <Row className="OLearing-section">
              <Col xl={6} lg={6} md={6} sm={6}>
                <div
                  className="Online-learnig-Content text-align-center"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1500"
                  onScroll="AOS"
                >
                  <h6>~ online learning</h6>
                  <h2>Access Your Classes Anytime Anywhere</h2>
                  <p>
                  Welcome to our platform, where you can access your classes anytime,
                   anywhere through recorded sessions.
                   At OSA, we understand the importance of flexibility and convenience in your learning journey.
                    That's why we offer a wide range of recorded classes that you can access
                     at your own pace and on your own schedule.
                  </p>
                  <p>
                  With our platform, you no longer have to worry about missing a class or rearranging your schedule 
                  to accommodate fixed class times. Whether you're a busy professional, a student with a hectic 
                  schedule, or someone who prefers learning at their own pace, our recorded sessions allow you to
                  learn whenever and wherever it's most convenient for you.
                  </p>
                  Key features of our platform include:
                  <ul>
                    <li>On-Demand Access</li>
                    <li>Availability</li>
                    <li>Flexible Learning</li>
                    <li>Pause and Rewind</li>
                    <li>Progress Tracking</li>
                     </ul>
                  <div>
                    <Button className="btn-box-common">View All Courses</Button>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6}>
                <img src={img1} alt="OLearing" className="mx-auto img-fluid" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
