import React from "react";

import { BreadCrumb } from "./BreadCrumb";
import { Forgotbox } from "./Forgotbox";

export const ForgotPwd = () => {
  return (
    <>
      <BreadCrumb/>
      <Forgotbox/>
    </>
  );
};
