import img1 from "../../../../assets/Gallery/Gallery01.jpg";
import img2 from "../../../../assets/Gallery/Gallery02.jpg";
import img3 from "../../../../assets/Gallery/Gallery03.jpg";
import img4 from "../../../../assets/Gallery/Gallery04.jpg";
import img5 from "../../../../assets/Gallery/Gallery05.jpg";
import img6 from "../../../../assets/Gallery/Gallery06.jpg";
import img7 from "../../../../assets/Gallery/Gallery07.jpg";
import img8 from "../../../../assets/Gallery/Gallery08.jpg";
import img9 from "../../../../assets/Gallery/Gallery09.jpg";

export const GalleryData = [
    {
        id: 1,
        image: img1,
    },
    {
        id: 2,
        image: img2,
    },
    {
        id: 3,
        image: img3,
    },
    {
        id: 4,
        image: img4,
    },
    {
        id: 5,
        image: img5,
    },
    {
        id: 6,
        image: img6,
    },
    {
        id: 7,
        image: img7,
    },
    {
        id: 8,
        image: img8,
    },
    {
        id: 9,
        image: img9,
    },
]